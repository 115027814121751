@import '~antd/dist/antd.css';
@import './partials/_colors.scss';
@import './dependencies/_toastr.scss';
@import './bootstrap/bootstrap.min.css';
@import './fontawesome/css/font-awesome.min.css';

@import './partials/_animations.scss';
@import './partials/_elements.scss';
@import './partials/_objects.scss';
@import './partials/_components.scss';
@import './partials/_typography.scss';
@import './partials/_utilities.scss';
@import './partials/_ant-overrides.scss';

* {
    box-sizing: border-box;
}
html{
    background: $rodeodust;
    max-width: 100%;
    overflow-x: hidden;
}
body{
    background: $rodeodust;
    overflow-x: hidden;
    height: auto;
    overflow-y: hidden;
}
.logoimage {
    max-width: 300px;
}
img {
    width: 100%;
    height: auto;
}
.sandbg {
    background: $rodeodust;
}
.row{
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.row-centered {
    text-align:center;
    .card, .centered-col{
        display:inline-block;
        float:none;
        text-align:left;
        margin-right:-4px;
        vertical-align: top;
    }
}
