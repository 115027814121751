
// Primary colors
$deepocean: #004755;
$darkocean: #036D82;
$ocean: #008eaa;
$summersky: #80BCC8;
$oceanfoam: #D9EDEF;
$iceblue: #F0F6F8;


// Secondary colors
$bark: #604731;
$mulefawn: #8D6443;
$sand: #BE8C64;
$desert: #DCC8AF;
$rodeodust: #F5EAE3;
$beach: #FAF7F1;

$actiondropdown: #f5ebe6;

// Complimentary colors
$orangegold: #F39200;
$darkorange: #C07200;


$pine: #31663C;
$meat: #CC756B;
$violet: #875681;

//info
$blue: #2F80ED;
$bluemedium: #78ACF3;
$bluelight: #CBDFFA;


//Success
$green: #00A04B;
$greenmedium: #9BCDA0;
$greenlight: #D9F1E4;

//warn
$yellow: #F2C94C;
$yellowmedium: #F7DD8D;
$yellowlight: #FBECC1;

//Error
$red: #DE4953;
$redmedium: #F09678;
$redlight: #FBDADD;

$black: #191514;
$nightrider: #333333;
$emperor: #555555;
$grey: #848484;
$silver: #C0C0C0;
$gallery: #EAEAEA;
$lightsmoke: #F7F7F7;
$white: #FFF;