.c-btn {
  width: 100%;
  display: block;
  background-color: $orangegold;
  color: #ffffff;
  border: 0;
  font-size: 1.6rem;
  text-align: center;
  border-radius: 6px;
  text-decoration: none;
  outline: none;
  transition: all 0.2s;
  height: auto;
  position: relative;
  padding: 8px 16px;

  &.small {
    width: auto;
    padding: 6px 12px;
  }
  &.large {
    padding: 12px 24px;
  }

  &.bigger {
    padding: 12px 24px;
  }

  &.secondary {
      color: $nightrider;
      border: 1px solid $grey;
      background-color: $white;
      &:focus,
      &:active {
        background-color: $silver;
      }
      &:hover {
        background-color: $gallery;
      }
  }

  &.round {
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 3px rgba(0, 0, 0, 0.1);
    width: 45px;
    height: 45px;
    transition: all 0.2s;
    &.fa {
      font-weight: 500;
      font-size: 2rem;
    }
    &:hover {
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.23);
    }
  }
  &.align-right {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  &.align-right-top {
    position: absolute;
    right: 10px;
    top: -20px;
    z-index: 9999;
  }
  &:focus{
      outline: 2px solid $ocean;
  }
  &:focus,
  &:active {
    background-color: $mulefawn;
  }
  &:hover {
    background-color: $darkorange;
  }
  &.blue {
    background-color: $ocean;
    color: #fff;
    display: block;

    &:hover {
      background-color: #006f85;
    }
    &:focus,
    &:active {
      background-color: $ocean;
    }
  }
  &.red {
    background-color: $red;
    color: #fff;
    display: block;

    &:hover {
      background-color: $meat;
    }
    &:focus,
    &:active {
      background-color: $meat;
    }
  }

  &.grey,
  &:disabled {
    background-color: $gallery;
    color: $silver;
    display: block;
    cursor: not-allowed;
  }
  &.deactive {
    background-color: #75c8d7;
    color: #c4e8ee;
    display: block;
    cursor: not-allowed;
    &:hover {
      background-color: #1fbbd4;
      color: #fff;
    }
    &:focus,
    &:active {
      background-color: #1fbbd4;
      color: #fff;
    }
  }
  & a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
}

.dropzone {
  background: $lightsmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  text-align: center;
  height: 150px;
  position: relative;
  transition: all 0.25s ease-in-out;
  border-top: dashed 2px #dee1e2;
  border-bottom: dashed 2px #dee1e2;
  &.active {
    border-top: solid 2px $gallery;
    border-bottom: solid 2px $gallery;
  }
  &.file {
    border-top: solid 2px $green;
    border-bottom: solid 2px $green;
  }
  span {
    font-size: 2rem;
    color: #646464 !important;
    cursor: pointer;
  }
  > div:not(.file-info) {
    width: 100% !important;
    border-width: 0px !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
    }
  }
  .file-info {
    transition: all 0.25s ease-in-out;
    height: 0;
    opacity: 0;
    visibility: hidden;
    background: $greenmedium;
    width: 100%;
    color: $white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    p,
    span,
    i {
      color: $white !important;
    }
    &.file {
      height: 100%;
      opacity: 100;
      visibility: visible;
    }
  }
}
.o-login-page-checkbox {
  .lbl {
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 220px;
    background: transparent;
    border-radius: 100px;
    min-height: 0 !important;
    cursor: pointer;
    transition: all 1s ease;
    border: 2px solid $ocean;
    padding-left: 0 !important;
    text-transform: uppercase;
    color: $ocean;
    font-weight: 500;
    font-size: 1.3rem;
    letter-spacing: 1px;
    &:after {
      position: absolute;
      left: 5px;
      top: 3px;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 100px;
      background: $ocean;
      box-shadow: 0px 3px 3px rgba(#000, 0.05);
      border: 1px solid $ocean;
      content: '';
      transition: all 0.7s ease;
    }

    &:active {
      &:after {
        transform: scale(1.15, 0.85);
      }
    }
  }

  .cbx[value='true'] ~ label {
    background: #6fbeb5;
    border: 2px solid transparent;
    color: whitesmoke;
    &:after {
      left: 90%;
      background: transparent;
      border: 0px solid transparent;
      box-shadow: 0px 0px 0px rgba(#000, 0);
    }
  }

  .cbx:disabled ~ label {
    background: #d5d5d5;
    pointer-events: none;
    &:after {
      background: #bcbdbc;
    }
  }

  .hidden {
    display: none;
  }
}
// Switch classes
.lbl {
  position: relative;
  display: block;
  height: 12px;
  width: 30px;
  background: #898989;
  border-radius: 100px;
  min-height: 0 !important;
  cursor: pointer;
  transition: all 0.3s ease;
  &:after {
    position: absolute;
    left: -4px;
    top: -1.8px;
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 100px;
    background: white;
    box-shadow: 0px 3px 3px rgba(#000, 0.05);
    border: 1px solid lightgray;
    content: '';
    transition: all 0.3s ease;
  }

  &:active {
    &:after {
      transform: scale(1.15, 0.85);
    }
  }
}

.cbx[value='true'] ~ label {
  background: #6fbeb5;
  &:after {
    left: 20px;
    background: #179588;
    border: 0px solid transparent;
  }
}

.cbx:disabled ~ label {
  background: #d5d5d5;
  pointer-events: none;
  &:after {
    background: #bcbdbc;
  }
}

.hidden {
  display: none;
}

.change-requests-container {
  padding-bottom: 1000px;
  .change-row {
    border-bottom: solid 1px #f0f0f0;
    // &:nth-child(2n) {
      // background: #f4f4f4;
    // }
    transition: all 0.25s ease-in-out;
    padding: 2rem 0;
    &.disabled {
      background: #f4f4f4;
    }
  }
  .change-request-option-status {
    min-width: 75px;
  }
}

.changerequestsLinkContainer {
  position: relative;
  // https://stackoverflow.com/questions/5069110/remove-hover-css-behavior-from-element
  pointer-events: none;
  padding: 0 !important;
  span {
    position: absolute;
    border-radius: 5px;
    font-size: 12px;
    height: 22px;
  }
  @media screen and (min-width: 768px) {
    span {
      right: 5px;
      top: 15px;
    }
  }
  @media screen and (max-width: 768px) {
    span {
      left: 150px;
    }
  }
}

.c-jumbotron {
  background: #fff;
  border-bottom: 1px solid lightgray;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.c-userToggle {
  border-top: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
