.wiggle{
    -webkit-animation-name: wiggle;
    -ms-animation-name: wiggle;
    -ms-animation-duration: 1000ms;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: 5;
    -ms-animation-iteration-count: 5;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
  }
  .wiggle:hover {
    animation: none;
  }

  @keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
  }

  /* Transition */
.slide-up-appear {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s ease;
  
  &.slide-up-appear-active {
      opacity: 1;
      transform: translateY(0px);
  }
}
.fadeIn-enter {
  opacity: 0;
  //transform: translateY(100px);
  transition: 0.5s ease-in-out all;
}

.fadeIn-enter.fadeIn-enter-active {
  opacity: 1;
  //transform: translateY(0px);
}