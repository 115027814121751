.o-bg-fff {
  background: white;
}

.o-card-options-container {
  min-height: 168px;
  border-top: 1px solid $lightsmoke;
}

.o-button-notification {
  background-color: $red;
  color: #fff;
  z-index: 10;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
  width: 25px;
  height: 25px;
  text-transform: bold;
  border-radius: 5px;
  transition: all 0.25s ease-in-out;
  .fa {
    font-size: 1.7rem;
  }
  &.green {
    background: $oceanfoam !important;
    color: $ocean;
    border-radius: 50%;
  }
}

.o-register-card-header {
  display: flex;
  align-items: flex-start;

  .o-button-notification {
    min-width: 35px;
    min-height: 35px;
  }
  .register-name-container {
    min-height: 50px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    strong {
      color: black;
      font-size: 1.7rem;
      margin-bottom: 5px;
    }
    p {
      margin: 0;
      font-size: 1.3rem;
      min-height: 20px;
    }
  }
}
.o-action-button,
.o-action-dropdown-button {
  padding: 11px 6px 8px;
  background: $actiondropdown;
  color: $mulefawn;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  width: 35px;
  .fa {
    font-size: 15px;
  }
  display: block;

  &.disabled {
    background: gray !important;
    color: lightgray;
    cursor: not-allowed;
  }
}
.o-action-dropdown-container {
  position: relative;
  .actions-container {
    background: $white;
    border: 1px solid $bark;
    padding: 20px;
    border-radius: 5px;
    position: absolute;
    top: 40px;
    width: 300px;
    z-index: 999999999;
    right: 15px;
    display: none;
    height: auto;
    border-radius: 5px;

    &:not(.no-overflow) {
      overflow-x: hidden;
    }
    &.open {
      display: block;
    }
    .action-option {
      strong,
      a {
        font-weight: 500 !important;
      }
      .o-button-notification {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
.o-action-dropdown {
  &.left {
    position: relative;
    .actions-container {
      left: 0;
    }
  }
  &.target-group-list {
    position: relative;
    .actions-container {
      right: calc(100% - 35px);
    }
  }
  .actions-container {
    position: absolute;
    top: 40px;
    width: 300px;
    z-index: 999999999;
    right: 15px;
    display: none;
    height: auto;
    border-radius: 5px;

    &:not(.no-overflow) {
      overflow-x: hidden;
    }
    &.open {
      display: block;
    }
    .action-option {
      strong,
      a {
        font-weight: 500 !important;
      }
      .o-button-notification {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
.action-container {
  background: $white;
  border: 1px solid $bark;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
}
.o-table-container {
  width: 100%;
  // overflow-x: auto;
  & > div {
    overflow-x: auto;
    overflow-y: visible;
    width: 100%;
    height: auto;
    // padding-bottom: 150px;
  }
  table thead tr th {
    min-width: 250px;
  }
}

.o-draggable-container {
  display: flex;
  align-items: flex-start;
  width: auto;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  .o-draggable-item > div {
    border: 2px solid transparent !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 3px rgba(0, 0, 0, 0.1) !important;
    padding: 20px 25px !important;
    height: auto !important;
    min-height: 395px;
  }
}

.o-draggable-item {
  width: auto;
  min-width: 250px;
  flex: 0 0 auto;
  display: inline-block;
  padding: 10px;
  p,
  h4,
  .drag-handle {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
  }
  & > div {
    border-radius: 8px;
    background: #fafafa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px 25px;
    position: relative;
    transition: all 0.2s;
    height: 393px;
    h4 {
      margin: 25px 0;
    }
    p {
      margin: 0 -9px;
      padding: 5px 10px;
      font-size: 1.3rem;
      background: #fff;
    }
    p:nth-child(2n) {
      background: #f5f9fc;
    }
    .drag-handle {
      position: absolute;
      top: 10px;
      right: 10px;
      text-align: right;
      width: 95%;
      height: 30px;
      cursor: ew-resize;
    }
  }
}
.o-draggable-item:last-child > div {
  border: 1px solid $greenmedium;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
}
.__react_component_tooltip {
  font-weight: normal !important;
  min-width: 300px;
  text-align: center;
  &.show {
    opacity: 100 !important;
  }
}

.o-unstyled-link {
  text-decoration: none;
  color: inherit;
}
.o-validation-status-container {
  padding: 20px 20px 0 20px;
  display: flex;
  flex-flow: wrap row;
  align-items: flex-start;
  justify-content: space-around;
  .register-validation-status {
    padding: 15px 0;
    p:first-child {
      display: flex;
      align-items: center;
    }
    .validation-rules {
      &.multiple {
        > span {
          &:after {
            content: ', ';
          }
          &:nth-last-child(2) {
            &:after {
              content: '';
            }
          }
          &:last-child {
            &:before {
              content: ' & ';
            }
            &:after {
              content: '';
            }
          }
        }
      }
    }
  }
}
.o-register-view-paging {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .log-pagination {
    display: flex;
    padding: 20px 0;
    strong  {
      margin-right: 5px;
    }
    p {
      margin: 0 10px;
    }
  }
}
.o-log-container {
  padding: 10px;
  margin-bottom: 50px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  .log-paging {
    float: right;
  }
  .log-header {
    padding: 10px;
    background-color: $white;
    margin: -10px -10px 0 -10px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        display: inline-block;
      }
    }

    .log-pagination {
      p {
        margin-bottom: 0;
        margin-top: 20px;
        margin-right: 10px;
        font-size: 1.5rem;
        cursor: pointer;
      }
      @media screen and (min-width: 768px) {
        float: right;
        p {
          display: inline-block;
        }
      }
    }
  }
}

.modal-shade {
  display: none;
  opacity: 0;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.8) 100%);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  will-change: opacity;
}

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;

  .modal {
    opacity: 0;
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.07), 0px 5px 22px rgba(0, 0, 0, 0.06),
      0px 7px 8px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 5px;
    padding: 30px 45px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal-actions {
    align-self: flex-end;
  }
}
.o-settings-page-container {
  margin-bottom: 7px;
}
.o-page-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: -11px;
  font-size: 1.8rem;
  height: 64px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  div {
    padding-bottom: 15px;
    border-bottom: 7px solid transparent;
    transition: all 0.2s;
    @media screen and (max-width: 768px) {
      padding-bottom: 0px;
      margin-bottom: 20px;
    }
    cursor: pointer;
    &:hover {
      border-bottom: 7px solid darkgrey;
    }
    &.active {
      border-bottom: 7px solid $emperor !important;
    }
  }
  button {
    margin-bottom: 20px;
  }
}

.o-privacypolicy-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  background: white;
  width: 100%;
  min-height: 100%;
  height: auto;
  padding: 20px;
  .o-return {
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    font-size: 1.8rem;
    padding: 10px;
  }
}

.o-customer-auth-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  z-index: 9999;
  .content-container.welcomePhase {
    .e-lang-picker {
      color: rgb(112, 112, 112);
      span {
        color: rgb(112, 112, 112);
        strong {
          color: rgb(112, 112, 112);
          &:not(.active) {
            opacity: 0.5;
          }
        }
      }
      &:after {
        content: '|';
        font-size: 2rem;
        color: rgb(112, 112, 112);
        margin-left: 10px;
      }
    }
  }

  .o-return {
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    padding: 20px;
    z-index: 99999;
    width: 100%;
    font-size: 20px;
  }
  &.first-login,
  .login-page {
    margin-left: -15px;
    .method-container {
      background: none !important;
    }
  }

  .e-lang-picker:after {
    color: $emperor !important;
  }
  .relative-container {
    position: relative;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
  }

  @media screen and (min-width: 992px) {
    .bluebg {
      background: #35dcf2; /* Old browsers */
      background: -moz-linear-gradient(left, #35dcf2 39%, #35dcf2 39%, #008eaa 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        left,
        #35dcf2 39%,
        #35dcf2 39%,
        #008eaa 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to right,
        #35dcf2 39%,
        #35dcf2 39%,
        #008eaa 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35dcf2', endColorstr='#008eaa',GradientType=1 ); /* IE6-9 */
      height: 100%;
      position: absolute;
      width: 100%;
      right: 0;
      h3 {
        display: none;
      }
    }
    .sandbg {
      height: 100%;
      position: absolute;
      width: 100%;
      right: 0;
    }
    .promo-image {
      height: 60%;
      position: absolute;
      width: 55%;
      right: 5%;
      top: 20%;
      background: url('../images/desktop-lappari2x.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: 99999;
    }
    .logo-container.mobile {
      display: none;
    }
    .content-container {
      height: 100%;
      position: absolute;
      width: 65%;
      background: url('../images/desktop-valkoinen-osa-v2-2x.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .o-advance-button {
        margin-left: 0 !important;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1.2px;
        font-size: 1.4rem;
        padding: 15px 25px;
      }
      &.welcomePhase {
        .gobackbutton {
          display: none;
        }
      }
      .gobackbutton {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 66.66666667%;
      }
      h1 {
        font-size: 4rem;
        font-weight: 500;
        margin-bottom: 50px;
      }
      .logo path {
        fill: $ocean !important;
      }
    }
    .method-container {
      //background: white;
      min-height: 412px;
      padding-top: 90px;
      .identifier-card {
        .bottom-panel {
          margin: 0 -15px;
          justify-content: space-evenly !important;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .bluebg {
      background: #008eaa; /* Old browsers */
      background: -moz-linear-gradient(top, #008eaa 0%, #35dcf2 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        #008eaa 0%,
        #35dcf2 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        #008eaa 0%,
        #35dcf2 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008eaa', endColorstr='#35dcf2',GradientType=0 ); /* IE6-9 */
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      h3 {
        position: absolute;
        top: 35%;
        left: 0;
        width: 100%;
        color: white !important;
      }
    }
    .sandbg {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
    .promo-image {
      height: 200px;
      position: absolute;
      width: 86%;
      left: 0;
      top: 105px;
      background: url('../images/mobile-lappari3x.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: 99999;
      transition: 1s ease-in-out all;
      opacity: 1;
      &.anim-up {
        //top: -50%;
        transform: translateY(-400px);
        opacity: 0;
      }
    }
    .mobile-promo {
      height: 120px;
      position: absolute;
      width: 100%;
      left: 0;
      top: 105px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: 99999;
      transition: 1s ease-in-out all;
      opacity: 1;
      visibility: visible;
      &.anim-up {
        transform: translateY(-400px);
        opacity: 0;
        visibility: hidden;
      }
      &.fadeOut {
        opacity: 0;
        visibility: hidden;
      }
      .second-part {
        height: 120px;
        position: absolute;
        width: 100%;
        left: 0;
        top: -20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        z-index: 99999;
        transition: 1s ease-in-out all;
        opacity: 1;
        visibility: visible;
      }
    }
    .promo-image-mobile {
      background-image: url('../images/mobilelogin-bg3x.png');
      .second-part {
        background-image: url('../images/MOBILELOGIN3x.png');
      }
    }
    .promo-image-email {
      background-image: url('../images/EMAIL-LOGIN-LAPTOP3x.png');
      .second-part {
        background-image: url('../images/EMAIL-LOGIN-LETTER3x.png');
      }
    }
    .promo-image-all {
      height: 190px;
      top: 50px;
      background-image: url('../images/VALITSETUNNISTAUTUMISTAPA.png');
    }
    .logo-container.mobile {
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      .logo path {
        fill: #fff !important;
      }
      svg {
        width: 175px;
        height: auto;
      }
    }
    .content-container {
      height: auto;
      min-height: 100%;
      position: absolute;
      width: 100%;
      top: 27%;
      background: url('../images/mobile-valkoinenosa3x.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-bottom: 20px;
      padding-top: 70px;
      transition: 0.7s ease-in-out all;
      &.welcomePhase {
        padding-top: 120px;
      }
      &.anim-down {
        //top: 45%;
        transform: translateY(130px);
      }
      .auth-language-picker {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 10px;
      }
      .gobackbutton {
        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-in-out all;
        &.isactive {
          opacity: 1;
          visibility: visible;
        }
      }
      h1 {
        font-size: 2.8rem;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 0;
        text-align: center;
      }
      h2 {
        font-size: 1.4rem;
        margin-bottom: 20px;
        margin-top: 0;
        text-align: center;
      }
      h3 {
        margin-bottom: 10px !important;
      }
      p {
        font-size: 1rem;
        text-align: center;
      }
      .logo-container {
        display: none;
      }
    }
    .method-container {
      input {
        text-align: center;
      }
      .u-p-y-2 {
        padding-top: 10px;
      }
      h3 {
        display: none;
      }
      .u-p-x-8 {
        padding: 0 20px !important;
      }
      .identifier-card {
        .bottom-panel {
          margin: 0 -15px;
          justify-content: space-evenly !important;
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .promo-image {
      top: 130px;
    }
    .bluebg {
      h3 {
        top: 42% !important;
      }
    }
    @media screen and (min-height: 700px) {
      .bluebg {
        h3 {
          top: 35% !important;
        }
      }
    }
  }
}

.icon-container {
  div {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    height: 100px;
    width: 100%;
  }
  .email-icon {
    background-image: url('../images/Icon_Tunnistaudu_Sposti.png');
  }
  .phone-icon {
    background-image: url('../images/Icon_Tunnistaudu_Mobiili.png');
  }
  .ready-icon {
    background-image: url('../images/Icon_success_peukku.png');
  }
}

.o-deleted-register-info {
  min-height: 128px;
}

.o-logout-link {
  border-top: 1px solid white;
  margin-top: 5px;
  padding-top: 5px !important;
}

.o-p-10-0-20-0 {
  padding: 10px 0 20px 0;
}

.o-changerequest-card {
  border-radius: 0px 2px 2px 0px;
  > div > div {
    margin-left: 50px;
    margin-bottom: 10px !important;
  }
}
.o-statusbar-left {
  position: absolute;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  left: 0px;
  top: 0;
  width: 50px;
  height: 100%;
  border-radius: 2px 0px 0px 2px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  &.red {
    background-color: $red;
  }
  &.blue {
    background-color: $oceanfoam;
    color: $ocean;
  }
  &.green {
    background-color: $green;
  }
  &.black {
    background-color: $black;
  }
  &.gray {
    background-color: $silver;
  }
}

.o-list-row {
  padding: 10px 15px;
  border-top: 1px solid $lightsmoke;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.o-timestamp-top-right {
  position: absolute;
  top: 0px;
  right: 20px;
}

.o-toolbar,
.o-page-container {
  background: $white;
  color: $emperor;
  margin-bottom: 10px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
}
.o-toolbar-button {
  padding: 5px;
  text-align: center;
  margin-top: 10px;
  p {
    cursor: pointer;
  }
  @media screen and(min-width: 768px) {
    margin-top: 0px;
    border-left: 2px solid rgba(255, 255, 255, 0.6);
  }
}

.o-checkbox {
  margin-top: 10px;
  @media screen and(min-width: 768px) {
    display: inline-block;
    margin-top: 0;
  }
}

.o-editheaders {
  .editheaderinput {
    min-width: 233px;
  }
}

.o-editor-content {
  a {
    color: $ocean !important;
  }
}

.o-login-page-form {
  > div {
    @media screen and (min-width: 550px) {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  .form-group {
    & > div {
      @media screen and (min-width: 550px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  label {
    display: block;
  }
  input[type='text'] {
    display: inline-block;
    width: 100px;
    &:first-child() {
      margin-right: 10px;
    }
  }
  input[type='password'] {
    width: 210px;
  }
}

.o-collabsible-component {
  > div {
    min-height: 800px;
  }

  .o-wrap-collabsible {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 3px rgba(0, 0, 0, 0.1);
    input[type='checkbox'] {
      display: none;
    }
    .lbl-toggle {
      display: block;
      font-size: 1.5rem;
      text-transform: uppercase;
      text-align: left;
      padding: 1rem;
      color: $ocean;
      background: $oceanfoam;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin-bottom: 0;
    }
    .lbl-toggle::before {
      content: ' ';
      display: inline-block;

      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid currentColor;

      vertical-align: middle;
      margin-right: 0.7rem;
      transform: translateY(-2px);

      transition: transform 0.2s ease-out;
    }

    .collapsible-content .content-inner {
      background: #fff;
      border-bottom: 1px solid $oceanfoam;
      border-left: 1px solid $oceanfoam;
      border-right: 1px solid $oceanfoam;
      padding: 0.5rem 1rem;
      a {
        color: $ocean !important;
      }
    }
    .collapsible-content {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.5s ease-in-out;
    }
    .toggle:checked + .lbl-toggle + .collapsible-content {
      max-height: 3350px;
    }
    .toggle:checked + .lbl-toggle::before {
      transform: rotate(90deg) translateX(-3px);
    }

    .toggle:checked + .lbl-toggle {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.o-countrycode {
  @media screen and (max-width: 768px) {
    display: block !important;
    & > input:first-child {
      width: calc(100% - 15px);
      margin-bottom: 20px;
      display: inline-block;
    }
  }
  @media screen and (min-width: 768px) {
    & > input:first-child {
      max-width: 150px;
    }
  }
  .o-countrycode-list {
    position: absolute;
    border: 1px solid #ccc;
    padding: 10px 5px;
    width: calc(100% - 27px);
    display: none;
    max-height: 200px;
    overflow-y: auto;
    background: #fff;
    z-index: 999;
    @media screen and (max-width: 768px) {
      bottom: 85%;
    }
    @media screen and (min-width: 768px) {
      top: 110px;
      width: 230px;
    }
    &.open {
      display: block;
    }
    p {
      text-align: left;
      img {
        width: 23px;
        margin-right: 5px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
          0px 1px 3px rgba(0, 0, 0, 0.1);
      }
    }
  }
  input.o-countrycode {
    display: inline-block;
    margin-right: 5px;
    width: 250px;
    & + span {
      margin-right: 10px;
    }
  }
  .error-message {
    position: absolute;
    bottom: 80px;
    left: 145px;
    right: 15px;
  }
}

.o-target-group-page {
  background: white;
  margin-top: 7px;
  .o-table-container {
    padding-bottom: 30px;
    overflow-x: unset;
    table thead tr th {
      min-width: 100px;
      width: auto;
    }
  }
  .o-target-group-action-container {
    overflow: visible !important;
    .edit-target-group-form {
      width: 100%;
    }
    .action-option {
      overflow: visible !important;
    }
  }
  .wizard-container {
    display: flex;
    flex-direction: column;
    min-height: 550px;
    .progress-bar {
      display: flex;
      height: 40px;
      position: relative;
      background: $oceanfoam;
      border-radius: 3px;
      align-items: center;
      justify-content: center;
      .progress-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: $ocean;
        position: relative;
        overflow: hidden;
        flex-grow: 1;
        font-size: 1.6rem !important;
        transition: all 0.25s ease-in-out;
        // cursor: pointer;
        &.current {
          background-color: $ocean;
          color: white;
        }
      }
    }

    .target-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px 20px 20px;
      width: 100%;
      flex: 1;
      justify-content: space-between;

      .form-step-content {
        &.fullWidth {
          width: 100%;
        }
      }

      .filter-step {
        display: flex;
        position: relative;
        .filters-container {
          flex: 1;
        }
        .targeting-results-container {
          margin-left: 30px;
          width: 200px;
          padding: 0 20px 20px 0px;
          .results-header {
            display: flex;
            justify-content: flex-start;
            color: $ocean;
            &:not(:first-child) {
              margin-top: 5px;
            }
          }
          .result {
            display: flex;
            justify-content: flex-start;
            .info-text {
              display: flex;
              justify-content: flex-start;
              font-style: italic;
              width: 100%;
            }
          }
          .divider {
            border-bottom: 1px $summersky solid;
            margin: 10px 0;
            width: 100%;
          }
          .criteria-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            > span {
              font-size: 1rem;
            }
            .result-criteria-label {
              white-space: nowrap;
              margin-right: 5px;
            }
            .result-criteria-value {
              text-align: right;
            }
          }
        }
      }
      .step-navigation {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        button:last-child {
          margin-left: auto;
        }
      }
      .o-filter-summary-label {
        > strong {
          display: inline-block;
          max-width: 90%;
        }
      }
      .o-filter-value-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: wrap row;
        .o-filter-value-list-item {
          margin-right: 5px;
          margin-bottom: 5px;
          padding: 5px;
          background: $oceanfoam;
          color: $ocean;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > p {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 90%;
            margin: 0;
            > span {
              min-width: 50px;
              text-align: center;
            }
          }
        }
        .o-filter-value-list-diviver {
          margin-right: 5px;
          margin-bottom: 5px;
          padding: 5px;
          font-weight: bold;
        }
      }
    }
  }
}

.o-tooltip {
  margin-left: 5px;
  position: absolute;
  top: 0;
  right: -30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background: lightgray;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .__react_component_tooltip {
    max-width: 150px;
    text-align: left;
    transition: all 0.25s ease-in-out;
    line-height: 2rem;
  }
}

.o-pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  p {
    cursor: pointer;
    margin: 10px;
  }
}
.o-sorting-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  i.fa {
    transition: all 0.25s ease-in-out;
  }

  &.desc {
    i.fa.fa-chevron-circle-down {
      color: $ocean;
    }
  }
  &.asc {
    i.fa.fa-chevron-circle-up {
      color: $ocean;
    }
  }
}

.rdw-link-modal {
  left: -200px !important;
}
.o-product-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .o-product-variation {
    height: auto;
    min-height: 320px;
    width: 200px;
    margin: 0 15px;
    & > div {
      display: flex;
      flex-flow: wrap column;
      align-items: flex-start;
      justify-content: flex-start;
      height: auto;
      border-radius: 4px;
      background: $white;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      padding: 10px 10px 20px 10px;
      position: relative;
      transition: all 0.25s ease-in-out;
      min-height: 320px;
      border: 2px solid $silver;
      cursor: pointer;
      .ready-order-link {
        align-self: center;
        line-height: 1rem;
      }
      .product-selector {
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        border: 2px solid $silver;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        transition: background-color 0.25s ease-in-out;
        transition: border-color 0.25s ease-in-out;
        &.active {
          background-color: $mulefawn;
          border-color: $mulefawn;
          color: $white;
        }
      }
    }
    &:hover {
      > div {
        background: $lightsmoke;
        border: 2px solid $emperor;
      }
    }
    &.active {
      > div {
        background: $beach;
        border: 2px solid $bark;
      }
    }
  }
}

.o-register-card-bottom-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  > a {
    margin: 0 10px;
  }
}
