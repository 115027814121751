.logo path {
  fill: #000;
}

.desk-top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 200px;
  }
  .lang-picker-container {
    .fa-globe {
      color: $ocean;
      font-size: 20px;
    }
    span {
      margin: 0 5px;
    }
    .e-lang-picker {
      .active {
        color: $ocean;
      }
    }
  }
  .user-container {
    position: relative;
    span {
      color: $black;
      font-weight: 500;
    }
    .user-actions-container {
      position: absolute;
      width: 100%;
      left: 0px;
      top: 25px;
      height: auto;
      display: none;
      &.open {
        display: block;
      }
      a {
        display: block;
        padding: 0;
        margin: 0;
      }
    }
  }
}

nav.navbar {
  background: $oceanfoam;
  .navbar-toggle {
    float: left !important;
    position: absolute;
    display: block;
    width: 45px;
    height: 35px;
    z-index: 999;
    .icon-bar {
      transition: all 0.2s;
    }
    &[aria-expanded='true'] {
      .icon-bar {
        position: absolute;
      }
      .bar1 {
        transform: rotate(45deg);
        top: 14px;
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        transform: rotate(-45deg);
        top: 10px;
      }
    }
  }
  .navbar-header .logo-container {
    position: absolute;
    width: 100%;
    text-align: center;

    align-items: center;
    justify-content: center;
    height: 50px;
    top: 0;

    @media screen and (max-width: 768px) {
      display: flex !important;
    }
  }
  .navbar-collapse {
    padding: 15px;

    @media screen and (max-width: 768px) {
      padding-top: 65px;
      &.collapse {
        display: none;
      }
    }
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  width: 100%;
  margin-bottom: 0px !important;
  border-bottom: 1px solid $ocean;
  .e-mobilenav-links {
    border-bottom: 1px solid white;
    margin: 10px -15px;
    padding: 0 15px;
    width: 50%;
    a {
      display: block !important;
      div {
        margin: 10px 0;
      }
    }
  }
  a {
    color: black;
    font-size: 1.5rem;
    display: inline-block;
  }

  .main-nav {
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid $ocean;
    @media screen and (min-width: 768px) {
      padding-top: 10px;
    }
  }
  .navbar-toggle .icon-bar {
    background-color: $ocean;
  }
  .bottom-nav {
    background-color: $oceanfoam;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      padding: 17px;
      transition: all 0.2s;
      color: $ocean;
      font-size: 1.8rem;
      font-weight: normal;
      &.active {
        font-weight: 600;
        color: $ocean;
      }
    }
  }
}

.bottom-panel {
  display: flex;
  align-items: center;
  padding: 20px;
}

.content-right {
  justify-content: flex-end;
}

.auth-language-picker {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
  & > span:nth-child(5) {
    &:after {
      content: '' !important;
    }
  }
}
.e-lang-picker {
  color: black;
  span {
    color: black;
    strong {
      color: black;
      &:not(.active) {
        opacity: 0.5;
      }
    }
  }

  &:after {
    content: '|';
    font-size: 2rem;
    color: black;
    margin-left: 10px;
  }
}

.main-content-area {
  min-height: 800px;
  transition: all 0.3s;
  opacity: 100;
  padding-bottom: 300px;
}
.company-home-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    padding: 10px 20px;
    background: white;
    border-radius: 3px;
    border: 1px solid lightgray;
    min-width: 150px;
    text-align: center;
    cursor: pointer;
    &.active {
      background: $oceanfoam;
      border: 1px solid $summersky;
      color: $ocean;
      font-weight: 600;
    }
  }
}

span.active {
  font-weight: 500;
  text-decoration: underline;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.register-card textarea {
  min-height: 100px;
}

input,
textarea {
  background: #fff !important;
  border-bottom: solid 2px #8c8c8c !important;
  border-top: solid 0px transparent !important;
  border-left: solid 0px transparent !important;
  border-right: solid 0px transparent !important;

  outline: 0px #f8f8f8 !important;
  border-radius: 0px !important;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0) !important;
  transition: all 0.5s ease-in-out !important;
  &.disabled,&[disabled] {
    background-color: hsl(0, 0%, 95%) !important;
    border-color: hsl(0, 0%, 80%) !important;
    border-style: solid !important;
    border-width: 1px !important;
  }
  &.wide {
    width: 100%;
  }

  &:hover {
    background: #f5f5f5 !important;
  }
  &:focus,
  &:active {
    background: #fff !important;
    outline: 1px solid transparent !important;
    border-bottom: solid 2px $ocean !important;
    border-top: solid 0px transparent !important;
    border-left: solid 0px transparent !important;
    border-right: solid 0px transparent !important;
  }
  &.dark {
    background: #ededed !important;
  }
  &.short {
    max-width: 100px;
  }
  &.medium {
    max-width: 70%;
  }
  &.sharper {
    border-radius: 3px !important;
  }
  &.error {
    border-bottom-color: #ff0a5f !important;
    border-top: solid 0px transparent !important;
    border-left: solid 0px transparent !important;
    border-right: solid 0px transparent !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}
table td .o-tooltip {
  right: 10px;
}
.form-group {
  position: relative;
  .o-tooltip {
    right: 0 !important;
    top: -20px !important;
  }
}
.radio.blue {
  input {
    position: absolute;
    visibility: hidden;
    &:checked ~ .check {
      //border: 5px solid #0DFF92;
    }
    &:checked ~ .check::before {
      background: $ocean;
    }
    &:checked ~ label {
      //color: #47D7AC;
    }
  }
  label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 1.35em;
    padding: 25px 25px 25px 35px;
    margin: 10px auto;
    height: 30px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
  }
  .check {
    display: block;
    position: absolute;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    top: 20px;
    left: -5px;
    z-index: 5;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;
    &:before {
      background: #aaa;
      display: block;
      position: absolute;
      content: '';
      border-radius: 100%;
      height: 25px;
      width: 25px;
      top: 5px;
      left: 5px;
      margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }
  }
}
.radio-input-container {
  position: relative;
  > label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    font-weight: normal;
    &:hover {
      span {
        background-color: $summersky;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    input:checked ~ span {
      background-color: $ocean;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $oceanfoam;
      border-radius: 50%;
      transition: all 0.25s ease-in-out;
    }
  }
}

.checkbox-input-container {
  position: relative;
  &.checked {
    > label > span {
      background-color: $ocean;
    }
  }
  &:not(.checked) {
    > label label:hover > span {
      background-color: $summersky;
    }
  }
  > label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    font-weight: normal;
    transition: all 0.25s ease-in-out;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $oceanfoam;
      border-radius: 4px;
      transition: all 0.25s ease-in-out;
      color: $white;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.disabled {
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
    label {
      color: $silver !important;
    }
    label:hover span,
    span {
      background-color: $gallery !important;
    }
  }
}

.e-range-input-container {
  max-width: 250px;
  width: 100%;
  .slider {
    position: relative;
    margin: 50px auto 0 20px;
    width: 100%;
    height: 5px;
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    background: lightgray;

    .slider-min {
      background: lightgray;
      z-index: 2;
    }
    .slider-max {
      background: $ocean;
      z-index: 1;
    }
    .slider-min,
    .slider-max {
      position: absolute;
      top: 0;
      width: 0;
      height: 5px;
      border-radius: 5px;

      &::before {
        content: attr(data-content);
        display: block;
        position: absolute;
        top: -45px;
        right: -20px;
        width: 40px;
        padding: 3px;
        text-align: center;
        color: $ocean;
        background: $oceanfoam;
        border-radius: 3px;
        font-size: 1.3rem;
        transition: all 0.25s ease-in-out;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -22px;
        right: -8px;
        border-top: 8px solid $oceanfoam;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        transition: all 0.25s ease-in-out;
      }
      .slider-min-drag,
      .slider-max-drag {
        position: absolute;
        right: -7.5px;
        top: -5px;

        width: 15px;
        height: 15px;

        border-radius: 50%;
        background: $ocean;
        transition: all 0.25s ease-in-out;
        &:hover {
          box-shadow: 0 0 0 2px $ocean;
        }
      }
    }
    .slider-min.close {
      &::before {
        right: -5px;
      }
      &::after {
        right: -5px;
        border-top: 13px solid #ddeef2;
        border-left: 8px solid transparent;
        border-right: 2px solid transparent;
      }
    }
    .slider-max.close {
      &::before {
        right: -35px;
      }
      &::after {
        right: -5px;
        border-top: 13px solid #ddeef2;
        border-left: 2px solid transparent;
        border-right: 8px solid transparent;
      }
    }
  }
}
.e-select-container {
  width: 100%;
  &.e-select--is-disabled {
    .e-select__control {
      background-color: hsl(0, 0%, 95%) !important;
      border-color: hsl(0, 0%, 80%) !important;
      border-style: solid !important;
      border-width: 1px !important;
    }
  }
  .e-select__control {
    transition: all 0.25s ease-in-out;

    &.e-select__control--is-focused,
    &:hover {
      border-color: $ocean;
      box-shadow: none;
    }
    .e-select__value-container {
      p {
        margin: 0 !important;
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          margin-left: 5px !important;
        }
      }
      .e-select__single-value,.e-select__placeholder {
        margin-left: 5px;
      }
      .e-select__input {
        // opacity: 0;
        input {
          border-bottom: solid 0px transparent !important;
          transition: all 0s !important;
        }
      }

    }
  }
  .e-select__menu {
    z-index: 100;
  }
}

.e-daterange-input-container {
  .react-datepicker-wrapper {
    display: block;
  }
  .react-datepicker__tab-loop {
    .react-datepicker__header {
      background: $oceanfoam;
    }
    .react-datepicker {
      border-color: $ocean;
      .react-datepicker__triangle {
        border-bottom-color: $oceanfoam;
        &::before {
          border-bottom-color: $ocean;
        }
      }
      button.react-datepicker__navigation {
        height: 15px;
        width: 15px;
        border: 10px solid transparent;
        &.react-datepicker__navigation--previous {
          border-right-color: $ocean;
          left: -5px;
        }
        &.react-datepicker__navigation--next {
          border-left-color: $ocean;
          right: -5px;
        }
      }
    }
  }
}

.e-input-icon {
  background: transparent;
  color: $ocean;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  border-radius: 10px !important;
  &.grey {
    color: $silver;
  }
}

.card {
  padding-bottom: 4px;
  word-break: break-all;
  > div {
    background: #fff;
    position: relative;
    border-radius: 2px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  &.js-animated-card {
    padding: 10px 5px 10px 10px;
  }
}

.action-option {
  padding: 10px;
  overflow: hidden;
  border-bottom: 1px solid $gallery;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: all 0.2s;

  &.open-option-prompt {
    button.c-btn {
      margin-top: 15px;
    }
  }
  &:last-child {
    border-bottom: 1px solid transparent;
  }
}
.register-edit-bottom-row {
  margin-top: 30px;
  .col-md-6 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.error-message {
  margin: 10px 0;
  color: $red;
  border: 1px solid $red;
  padding: 10px;
}
.closed-option-prompt > div {
  transition: all 0.2s;
  &.slideOut {
    transform: translateX(150%);
  }
}
.js-prompt-delete-option,
.js-prompt-copyasbase-option {
  transform: translateX(150%);
  &.slideIn {
    transition: all 0.2s;
    transform: translateX(0%);
  }
}

.slideInLeft {
  transform: translateX(-150%);
  &.active {
    transition: all 0.2s;
    transform: translateX(0%);
  }
}

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  min-height: 600px;
  background: white;
  padding: 30px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
}
.rdw-editor-toolbar {
  margin-bottom: 10px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
}

.rdw-link-decorator-icon {
  left: 100% !important;
  top: -20px !important;
  height: 20px;
  width: 20px;
}
.rdw-link-modal {
  height: auto !important;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.07), 0px 5px 22px rgba(0, 0, 0, 0.06),
    0px 7px 8px rgba(0, 0, 0, 0.1) !important;
}
.rdw-editor-wrapper .rdw-editor-toolbar button {
  background: $orangegold !important;
  color: $ocean !important;
  border: 1px solid $orangegold !important;
  &:disabled {
    background: $gallery !important;
    color: $silver !important;
    border: 1px solid $gallery !important;
    cursor: not-allowed;
  }
}
.rdw-embedded-modal-header-label,
.rdw-image-modal-header-label.rdw-image-modal-header-label-highlighted,
.rdw-colorpicker-modal-style-label.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid $silver !important;
}

.react-datepicker {
  font-size: 1.5rem !important;
}

.react-datepicker__current-month {
  font-size: 1.7rem !important;
}

.react-datepicker__header {
  padding-top: 8px !important;
}

.react-datepicker__navigation {
  top: 16px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.7rem !important;
}

.react-datepicker__input-container {
  font-size: 1.3rem !important;
}

.react-tag-input {
  font-size: 1.3rem !important;
}

.ReactModalPortal {
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  .ReactModal__Content {
    border-radius: 0 !important;
    width: 100%;
    max-width: 600px;
  }
}
